
var showdown  = require('showdown')

const linkPattern = /\[([^\]]*?(?:\[.*?\][^\]]*?)*?)\]\(([^)]+)\)/g;
const boldPattern = /\*\*([^*]+)\*\*/g;
const multipleBrPattern = /(<br>\s*){2,}/g;
const removeHyphenPattern = /<br>\s*-\s*/g;
const numberPeriodSpacePattern = /\d+\.\s/g;
const markdownHeaderPattern = /#+\s/g;
const markdownHeaderPattern2 = /#+\s*$/g;

// regex pattern to parse out the actual url
const urlPattern = /(?:\((.*?)\))/g;

function standardizeTitle(title) {
  // Strip everything that isn't a letter or number, remove all whitespaces, and make it lowercase.
  if (!title) return '';
  return title.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
}

function convertMarkdownHeadersToHTML(text) {
  const headerMatches = [...text.matchAll(markdownHeaderPattern)];
  const headerIndices = headerMatches.map(match => match.index);
  const headerMatches2 = [...text.matchAll(markdownHeaderPattern2)];
  const headerIndices2 = headerMatches2.map(match => match.index);
  const headerIndices3 = headerIndices.concat(headerIndices2)
  // Generate three citation links
  const headers = headerIndices3.map((index, i) => {
    return `<h${i+1}>`;
  });

  // Insert citations after the selected periods
  headerIndices3.sort((a, b) => b-a); // Sort in descending order
  for (let i = 0; i < headerIndices3.length; i++) {
    const index = headerIndices3[i];
    text = text.slice(0, index) + headers[headerIndices3.length - i -1] + text.slice(index);
  }

  return text;
}


function longestCommonSubstring(str1, str2) {
  const matrix = Array(str1.length + 1).fill(null).map(() => Array(str2.length + 1).fill(0));
  let longestLength = 0;
  let endingIndexStr1 = 0;

  for (let i = 1; i <= str1.length; i++) {
    for (let j = 1; j <= str2.length; j++) {
      if (str1[i - 1] === str2[j - 1]) {
        matrix[i][j] = matrix[i - 1][j - 1] + 1;
        if (matrix[i][j] > longestLength) {
          longestLength = matrix[i][j];
          endingIndexStr1 = i;
        }
      } else {
        matrix[i][j] = 0;
      }
    }
  }

  if (longestLength === 0) {
    return '';
  } else {
    return str1.substring(endingIndexStr1 - longestLength, endingIndexStr1);
  }
}


function convertBulletPointsToHTMLList(text) {
  // Split the text into lines
  const lines = text.split('<div class="my-[15px] block"></div>');

  let inList = false;
  let newList = [];

  for (let line of lines) {
      // Check if the line is a bullet point
      if (line.trim().match(/^- /)) {
          if (!inList) {
              newList.push('<ul style="list-style-type: disc">');
              inList = true;
          }
          // Remove the markdown bullet and wrap the line in <li> tags
          newList.push(`<li>${line.trim().substring(2)}</li>`);
      } else {
          if (inList) {
              newList.push('</ul>');
              inList = false;
          }
          newList.push(line);
      }
  }

  // Close the list if the text ends with bullet points
  if (inList) {
      newList.push('</ul>');
  }

  return newList.join('<div class="my-[15px] block"></div>');
}

function fetchLinks(text, products=[]) {
  const standardizedText = standardizeTitle(text);
  const firstThreeProductTitles = products.slice(0, 3).map(product => standardizeTitle(product.title));
  let productDescriptionMap = {};
  let maxSubstringLength = 0;
  let bestMatchIndex = -1;

  for (let i = 0; i < firstThreeProductTitles.length; i++) {
    const commonSubstring = longestCommonSubstring(standardizedText, firstThreeProductTitles[i]);
    if (commonSubstring.length > maxSubstringLength) {
      maxSubstringLength = commonSubstring.length;
      bestMatchIndex = i;
    }
  }

  if (bestMatchIndex !== -1) {
    productDescriptionMap[products[bestMatchIndex].detailPageURL] = "relevant match found based on longest common substring";
    // console.log("Found THE ONE: ", products[bestMatchIndex].title);
  }

  return productDescriptionMap;
}

function shuffleArray(array) {
    // Implementation of the Fisher-Yates shuffle
    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
}

export default function parseMarkdown(markdownText, font, redditRefs=[], products=[]) {

  let converter = new showdown.Converter()
  let htmlText = converter.makeHtml(markdownText);
  //  var htmlText = markdownText.replace(removeHyphenPattern, '');
  //  htmlText = htmlText.replace(numberPeriodSpacePattern, '');

   // Replace markdown links with HTML structure for links
   let pad = "10px"
   let img_size = "20px"
   if (font == "sm") {
       pad = "8px"
       img_size = "16px"
   }


  const productDescriptionMap = fetchLinks(htmlText, products)

  //  htmlText = htmlText.replace(linkPattern, (match, text, url) => {

  //      let modifiedText = text.length > 130 ? text.substring(0, 130) + '...' : text;
  //      if (!modifiedText.startsWith("**")) {
  //         modifiedText = "**" + modifiedText
  //      }
  //      if (!modifiedText.endsWith("**")) {
  //       modifiedText = modifiedText + "**"
  //      }

  //      return modifiedText;
  //  });
  //  htmlText = htmlText.replace(/\n/g, '<div class="my-[15px] block"></div> ')

   // Identify and replace multiple consecutive divs
  //  var multipleDivPattern = /(<div class="my-\[15px\] block"><\/div>\s*){2,}/g;
  //  htmlText = htmlText.replace(multipleDivPattern, '<div class="my-[15px] block"></div>');

   // Apply other markdown replacements
  //  htmlText = htmlText.replace(boldPattern, '<b>$1</b>');

  //  htmlText = convertBulletPointsToHTMLList(htmlText);
   // Now, apply citations after HTML conversion
  // if (redditRefs && redditRefs.length > 0) {

    //try and connect recs --> product Recommended --> text of Reddit Refs to determine which ones should show

    // try {
    //   const periodMatches = [...htmlText.matchAll(/(?<!\.{2})[.!](?!\.)/g)];
    // } catch (error) {
    //     console.error("Error matching periods with lookahead:", error);
    // }
    // const periodMatches = [...htmlText.matchAll(/\.[\s$]/g)];
    // const periodIndices = periodMatches.map(match => match.index); // This points to the period itself
    // shuffleArray(periodIndices);
    // // Select up to three unique positions
    // const selectedIndices = periodIndices.slice(0, Math.min(3, periodIndices.length));

    // // Generate three citation links
    // const citations = selectedIndices.map((index, i) => {
    //   const randomRedditRef = redditRefs[i % redditRefs.length];
    //   return `<a href="${randomRedditRef.link}" target="_blank" rel="noopener noreferrer" class="inline-block"><sup class="text-xs text-gray-700 align-super font-mono"><span class="relative inline-flex items-center justify-center h-4 w-4 ml-[2px] bg-gray-200 rounded-full">${i+1}</span></sup></a>`;
    // });

    // // Insert citations after the selected periods
    // selectedIndices.sort((a, b) => b-a); // Sort in descending order
    //         for (let i = 0; i < selectedIndices.length; i++) {
    //         const index = selectedIndices[i];
    //         htmlText = htmlText.slice(0, index) + citations[selectedIndices.length - i -1] + htmlText.slice(index);
    //         }
    // }

   return [htmlText, productDescriptionMap]
}