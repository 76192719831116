export function extractHostname(url) {
    if (!url) return '';

    let hostname;
    if (url.indexOf("//") > -1) {
        hostname = url.split('/')[2];
    } else {
        hostname = url.split('/')[0];
    }
    // Find & remove port number
    hostname = hostname.split(':')[0];
    // Find & remove "?"
    hostname = hostname.split('?')[0];
    // Remove "www." if present
    if (hostname.startsWith("www.")) {
        hostname = hostname.substring(4);
    }
    if (hostname && hostname.length > 14) {
        hostname = hostname.substring(0, 14) + '...';
    }
    return hostname;
}

export function formatHostname(hostname: string): string {
    // Remove "Seller" if present
    hostname = hostname.replace("- Seller", "");
    // Truncate and add ellipsis if longer than 14 characters
    if (hostname.length > 14) {
        hostname = hostname.substring(0, 14) + '...';
    }
    return hostname;
}
